// src/content/infrastructure/ui-services/bannerUiService.ts

import type { BannerCard, BannerCardPage, BannerHome } from "~/src/content/domain/models/Banner";
import type { NitroFetchOptions } from "nitropack";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

interface BannerUiService {
  getHomeTopBanner: (lang: string) => Promise<BannerHome | undefined>;
  getCardBanner: (page: BannerCardPage, lang: string) => Promise<BannerCard | undefined>;
}

const bannerUiService = (): BannerUiService => ({
  getHomeTopBanner,
  getCardBanner,
});

export default bannerUiService;

function getCurrentRegionId(): number | undefined {
  const { region } = useCompetitionStore();
  return region?.id;
}

async function getHomeTopBanner(lang: string): Promise<BannerHome | undefined> {
  const regionId = getCurrentRegionId();
  const { webContext } = useWebContext();
  if (!regionId) {
    console.error("Error on fetch home top banner data: Impossible to define regionId.");
    return undefined;
  }

  let path = `/api/cms/${regionId}/banners/banner-home-top`;
  const opts: NitroFetchOptions<any> = { query: { lang, ctx: webContext } };

  try {
    const data = await $fetch<BannerHome | undefined>(path, opts);
    if (data) {
      return data;
    } else {
      return undefined;
    }
  } catch (e) {
    console.error("Error fetching banners home: ", e);
  }
}

async function getCardBanner(page: BannerCardPage, lang: string): Promise<BannerCard | undefined> {
  const regionId = getCurrentRegionId();
  const { webContext } = useWebContext();
  if (!regionId) {
    console.error("Error on fetch card banner data: Impossible to define regionId.");
    return undefined;
  }
  if (!page) {
    console.error("Error on fetch card banner data: No page provided.");
    return undefined;
  }

  const opts: NitroFetchOptions<any> = { query: { lang, ctx: webContext } };
  let path = `/api/cms/${regionId}/banners/banner-${page}-card`;

  try {
    const data = await $fetch<BannerCard | undefined>(path, opts);
    if (data) {
      return data;
    } else {
      return undefined;
    }
  } catch (e) {
    console.error("Error fetching card banners: ", e);
  }
}
